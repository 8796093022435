export const selectText = (v) => {
    switch (v.toUpperCase()) {
        case 'NO':
            return ['No', 'color-green'];
        case 'SOMETIMES':
            return ['Sometimes', 'color-warn'];
        case 'YES_CANNOT_CHANGE':
        case 'YES':
            return ['Yes', 'color-red'];
    }
};

import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Preloader from '../preloader';
import MobileSubPage from '../mobile-sub-page';
import { selectText } from './utils';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { SPOT_PRESENCE_STATE } from '../../consts/spot.consts';
import DesignedForPrivate from '../designed-for-private';
import ModalMobile from '../modal-mobile';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgCommentQuote from 'src/assets/svg/SvgCommentQuote.svg';
import SvgPeopleGroup from 'src/assets/svg/SvgPeopleGroup.svg';
import SvgChicken from 'src/assets/svg/SvgChicken.svg';
import SvgDoor from 'src/assets/svg/SvgDoor.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';

const useGetSpotDistractions = require('../../services/hooks/useGetSpotDistractions');

const LIMIT_PER = 5;

const presentsTitle = {
    dogsPresent: ['Other dogs', <SvgDog key="dogs" className="icon-title" />],
    domesticAnimalsPresent: ['Other domestic animals', <SvgChicken key="animals" className="icon-title" />],
    peoplePresent: ['Other people', <SvgPeopleGroup key="people" className="icon-title" />],
    prvtEntry: ['Private entry', <SvgDoor key="private" className="icon-title" />],
};

const presentTypes = {
    dogsPresent: 'DOGS_PRESENT_COMMENT',
    domesticAnimalsPresent: 'DOMESTIC_ANIMALS_PRESENT_COMMENT',
    peoplePresent: 'PEOPLE_PRESENT_COMMENT',
};

const PresentContent = ({
    canShowMore,
    canShowReview,
    canShowReviewComments,
    ensurePrivate,
    describe,
    loadMore,
    percentages,
    present,
    revComments,
    type,
}) => (
    <>
        <p className={`snif-s1 snif-semibold ${selectText(present)[1]} ${type === 'prvtEntry' ? 'prvt' : ''} mb-2`}>{SPOT_PRESENCE_STATE[present]}</p>
        <p className="snif-s1 mb-3 text-pre">{describe}</p>
        {percentages !== null && (
            <div className="present-feedback">
                <p className="snif-xs snif-semibold text-medium">GUEST FEEDBACK</p>
                <p className="field snif-p text-dark mt-05">
                    <span className="snif-semibold">{percentages}%</span> of guests saw/heard
                </p>
            </div>
        )}
        {canShowReview && (
            <>
                {revComments ? (
                    canShowReviewComments ? (
                        <div>
                            {revComments.collection.map((r) => {
                                const rDate = moment(r.submittedAt).format('MMM D, YYYY');

                                return (
                                    <div className="rev-comment-item" key={r.id}>
                                        <SvgCommentQuote className="me-1" />
                                        <div>
                                            <p className="snif-s1 text-medium mb-1">
                                                <span className="snif-semibold text-deep">{r.providedBy.nameAndInitial}</span> · <span>{rDate}</span>
                                            </p>
                                            <p className="snif-s1 text-deep">{r.comment}</p>
                                        </div>
                                    </div>
                                );
                            })}
                            {canShowMore && (
                                <p className="snif-s1 snif-semibold text-primary text-underline c-pointer mb-3" onClick={loadMore}>
                                    Load more feedback
                                </p>
                            )}
                        </div>
                    ) : (
                        <p className="snif-s1 text-medium d-flex align-items-center mb-3">
                            <SvgCommentQuote className="me-1" />
                            <span className="rev-no-comment">Guests' provided no additional comments</span>
                        </p>
                    )
                ) : (
                    <div className="my-3">
                        <Preloader full />
                    </div>
                )}
            </>
        )}
        {ensurePrivate && <DesignedForPrivate />}
    </>
);

const RenderPresentMob = ({
    canShowMore,
    canShowReview,
    canShowReviewComments,
    ensurePrivate,
    showModal,
    toggle,
    type,
    describe,
    loadMore,
    percentages,
    present,
    revComments,
}) => (
    <MobileSubPage show={showModal} onBack={toggle} title={`${presentsTitle[type][0]}`} className="present-modal">
        <PresentContent
            {...{
                canShowMore,
                canShowReview,
                canShowReviewComments,
                ensurePrivate,
                describe,
                loadMore,
                percentages,
                present,
                revComments,
                type,
            }}
        />
    </MobileSubPage>
);

const RenderPresentDes = ({
    canShowMore,
    canShowReview,
    canShowReviewComments,
    ensurePrivate,
    showModal,
    toggle,
    type,
    describe,
    loadMore,
    percentages,
    present,
    revComments,
}) => (
    <ModalMobile isOpen={showModal} toggle={toggle} title={`${presentsTitle[type][0]}`} className="present-modal">
        <PresentContent
            {...{
                canShowMore,
                canShowReview,
                canShowReviewComments,
                ensurePrivate,
                describe,
                loadMore,
                percentages,
                present,
                revComments,
                type,
            }}
        />
    </ModalMobile>
);

export const Present = ({ present, describe, showModal, toggle, type, ensurePrivate, percentages, spotId, serverSSR }) => {
    const isMobile = useMobileScreen();
    const { getSpotDistractions } = serverSSR ? {} : useGetSpotDistractions.default();
    const [currLimit, setCurrLimit] = useState(2);
    const [revComments, setRevComments] = useState(null);
    const [ModalPresent, setModalPresent] = useState(() => RenderPresentMob);
    const totalComments = revComments?.metadata?.totalCount;
    const canShowMore = revComments && totalComments > 2 && currLimit < totalComments;
    const canShowReview = Boolean(percentages) && percentages > 0;
    const canShowReviewComments = revComments && revComments?.metadata?.totalCount > 0;

    const ModalPresentComp = (
        <ModalPresent
            {...{
                canShowMore,
                canShowReview,
                canShowReviewComments,
                ensurePrivate,
                showModal,
                toggle,
                type,
                describe,
                loadMore,
                percentages,
                present,
                revComments,
            }}
        />
    );

    const handleToggle = () => {
        if (isMobile) {
            toggle(type);
        }
    };

    const getRevs = async ({ page, limit }) => {
        const res = await getSpotDistractions({
            variables: {
                id: spotId,
                type: presentTypes[type],
                page,
                limit,
            },
        });
        return res;
    };

    const loadMore = async () => {
        const newLimit = currLimit + LIMIT_PER > totalComments ? totalComments : currLimit + LIMIT_PER;
        const res = await getRevs({ page: 1, limit: newLimit });
        setRevComments(res.data?.spot?.reviewComments);
        setCurrLimit(newLimit);
    };

    useEffect(() => {
        if (!showModal) {
            return;
        }

        const init = async () => {
            const res = await getRevs({ page: 1, limit: 2 });
            setRevComments(res.data?.spot?.reviewComments);
        };

        init();
    }, [showModal]);

    useEffect(() => {
        !isMobile && setModalPresent(() => RenderPresentDes);
    }, []);

    if (!present) {
        return '';
    }

    return (
        <div className="col-12 col-md-6 present-block">
            <div className="field snif-semibold d-flex" onClick={handleToggle}>
                <span>{presentsTitle[type][1]}</span>
                <p className="mb-0">
                    <span className="d-block mb-1">{presentsTitle[type][0]}</span>
                    <span className={`snif-medium ${selectText(present)[1]} ${type === 'prvtEntry' ? 'prvt' : ''}`}>{selectText(present)[0]}</span>
                    <SvgChevronRight className="icon-info" onClick={() => toggle(type)} />
                    <span className="present-chev-r">
                        <SvgChevronRight />
                    </span>
                </p>
            </div>
            {ModalPresentComp}
        </div>
    );
};
